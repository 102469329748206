import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack, AutoAwesome as AutoAwesomeIcon } from "@mui/icons-material";
import { Button, FormControl, TextareaAutosize, Tooltip } from "@mui/material";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import Moment from "moment";
import { Box, Flex, Input } from "@100mslive/roomkit-react";
import { ArrowRight, CircleFill, Edit, UploadFIle } from "../../icons";
import { LoginPopup } from "../Account/Login/LoginPopup";
import ImageCropper from "../Common/ImageCropper";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { EventAiPopup } from "./EventAiPopup";
import EventHostedAdd from "./EventHostedAdd";
import EventOptions from "./EventOptions";
import EventPreviewBox from "./EventPreviewBox";
import EventSpeakerAdd from "./EventSpeakerAdd";
import { SESSION_AUTH } from "../../common/auth";
import { AI_SERVICES, EVENT_SERVICES, ROOM_SERVICES } from "../../services";
import {
  EVENT_CONFIG,
  EVENT_TYPE,
  GET_PUBLIC_SESSION,
  MEETING_URL,
  REMOVE_PUBLIC_SESSION,
  SET_PUBLIC_SESSION,
} from "../../common/constants";
const EventCreate = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [eventName, setEventName] = useState("");
  const start = Moment(new Date());
  const remainder = 30 - (start.minute() % 30);
  const [eventDate, setEventDate] = useState(
    Moment(start).add(remainder, "minutes")
  );

  const [eventDescription, setEventDescription] = useState("");
  const [eventCover, setEventCover] = useState("");
  const [eventCoverPreview, setEventCoverPreview] = useState("");
  const [eventCoverAi, setEventCoverAi] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDraftEvent, setIsDraftEvent] = useState(true);
  const [currentStep, setCurrentStep] = useState("event");
  const [confirmSaveWork, setConfirmSaveWork] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loginRequest, setLoginRequest] = useState(false);
  const [eventTemplateID, setEventTemplateID] = useState("");
  const [eventMeetingLink, setEventMeetingLink] = useState("");
  const [eventRecordingInfoEnabled, setEventRecordingInfoEnabled] =
    useState(false);
  const [eventMeetingRoomID, setEventMeetingRoomID] = useState("");
  const [eventOwnerCustomerID, setEventOwnerCustomerID] = useState("");
  const [eventMeetingRegion, setEventMeetingRegion] = useState("");
  const [disableSurvey, setDisableSurvey] = useState(false);
  const [speakersData, setSpeakersData] = useState([]);
  const [eventType, setEventType] = useState(EVENT_TYPE?.public?.value);
  const [eventTBD, setEventTBD] = useState(false);
  const [eventAiType, setEventAiType] = useState("");
  const [eventCalendlyLinkAdd, setEventCalendlyLinkAdd] = useState(false);
  const [eventCalendlyLink, setEventCalendlyLink] = useState("");
  const [eventChatQA, setEventChatQA] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [hostedBy, setHostedBy] = useState([]);
  const [roomType, setRoomType] = useState(10000);
  const [orgAccountList, setOrgAccountList] = useState([]);
  const [cropEventCover, setCropEventCover] = useState("");

  const createRoom = async (returnData = false) => {
    setLoading(true);
    try {
      const data = await ROOM_SERVICES.createRoom({
        userId: userData?.userId,
        org: userData?.org?.id,
        name: returnData?.eventName || eventName || EVENT_CONFIG.eventName,
        description:
          returnData?.eventDescription ||
          eventDescription ||
          EVENT_CONFIG.eventDescription,
      });
      if (data.code === 200) {
        setLoading(false);
        setEventTemplateID(data.data.template_id);
        setEventMeetingLink(`${MEETING_URL}${data.data.id}`);
        setEventRecordingInfoEnabled(data.data.recording_info.enabled);
        setEventMeetingRoomID(data.data.id);
        setEventOwnerCustomerID(data.data.customer);
        setEventMeetingRegion(data.data.region);
        setCurrentStep("confirm");

        if (returnData) {
          return {
            eventTemplateID: data.data.template_id,
            eventMeetingLink: `${MEETING_URL}${data.data.id}`,
            eventRecordingInfoEnabled: data.data.recording_info.enabled,
            eventMeetingRoomID: data.data.id,
            eventOwnerCustomerID: data.data.customer,
            eventMeetingRegion: data.data.region,
          };
        }
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: "Someting went wrong" });
      }
      setLoading(false);
    } catch (error) {
      ToastManager.addToast({ title: error.message });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isUserSession) {
      setDataFetched(false);
      setUserData(isUserSession);
      setCurrentStep("event");
    } else {
      setUserData(null);
      setSpeakersData([]);
      setHostedBy([]);
    }
    return () => null;
  }, [isUserSession]);

  const handleLogin = () => {
    setLoginRequest(!loginRequest);
  };

  useEffect(() => {
    if (dataFetched) return;
    setDataFetched(true);
    let eventDraft = GET_PUBLIC_SESSION("eventDraft");
    eventDraft = eventDraft ? JSON.parse(eventDraft) : null;
    if (eventDraft && eventDraft?.id) {
      setEventName(eventDraft.eventName);
      if (eventDraft.eventDate) {
        setEventDate(eventDraft.eventDate);
      } else if (eventDraft?.eventTBD) {
        setEventTBD(eventDraft.eventTBD);
      }
      setEventDescription(eventDraft.eventDescription);
      setEventCoverPreview(eventDraft.eventCover);
      setEventCover("");
      setEventCoverAi("");
      if (eventDraft.disableSurvey) {
        setDisableSurvey(eventDraft.disableSurvey);
      }
      if (eventDraft.eventType) {
        setEventType(eventDraft.eventType);
      }
      REMOVE_PUBLIC_SESSION("eventDraft");
    }
    setIsDraftEvent(false);
    return () => null;
  }, [dataFetched]);

  const checkEventField = () => {
    if (userData) {
      if (hostedBy?.length > 0) {
        let checkHostedAllow;
        for (let hRow of hostedBy) {
          if (hRow?.allow) {
            checkHostedAllow = true;
            break;
          }
        }
        if (!checkHostedAllow) {
          ToastManager.addToast({
            title: `Enable a minimum of one list icon within the " Hosted By " section to display the company logo and name`,
          });
          return false;
        }
      } else {
        ToastManager.addToast({
          title:
            "At least one Hosted By (Organization)needs to be connected to this event",
        });
        return false;
      }

      if (speakersData?.length > 0) {
        let checkSpeaker;
        for (let s = 0; s < speakersData?.length; s++) {
          if (!speakersData?.[s].name) {
            checkSpeaker =
              " Please create speaker profile of  " + speakersData?.[s].email;

            break;
          } else if (!speakersData?.[s].email) {
            checkSpeaker = "Please add  again speaker";
            break;
          }
        }
        if (checkSpeaker) {
          ToastManager.addToast({
            title: checkSpeaker,
          });
          return false;
        }
      }
    }
    if (!eventName) setEventName(EVENT_CONFIG.eventName);
    if (!eventDescription) setEventDescription(EVENT_CONFIG.eventDescription);
    if (!eventCoverPreview) setEventCoverPreview(EVENT_CONFIG.eventCover);

    return true;
  };

  const saveEventWork = async () => {
    if (!checkEventField()) return false;
    setConfirmSaveWork(false);
    if (userData && (!eventTemplateID || !eventMeetingRoomID)) {
      setCurrentStep("event");
      setLoading(false);
      return;
    }
    setLoading(true);
    const eventdData = new FormData();
    eventdData.append("eventName", eventName.trim());
    if (eventDate) {
      eventdData.append("eventDate", eventDate);
    } else if (eventTBD) {
      eventdData.append("eventTBD", eventTBD);
    }

    eventdData.append(
      "eventDescription",
      eventDescription.replace(/\n/g, "<br />")
    );

    if (eventCover) {
      eventdData.append("eventCover", eventCover, eventCover.name);
    } else if (eventCoverAi) {
      eventdData.append("eventCover", eventCoverAi);
    } else {
      eventdData.append("eventCover", eventCoverPreview);
    }
    if (disableSurvey) {
      eventdData.append("disableSurvey", disableSurvey);
    }
    eventdData.append("eventType", eventType);

    if (userData) {
      eventdData.append("eventTemplateID", eventTemplateID);
      eventdData.append("eventMeetingLink", eventMeetingLink);
      eventdData.append("eventRecordingInfoEnabled", eventRecordingInfoEnabled);
      eventdData.append("eventMeetingRoomID", eventMeetingRoomID);
      eventdData.append("eventOwnerCustomerID", eventOwnerCustomerID);
      eventdData.append("eventMeetingRegion", eventMeetingRegion);
      eventdData.append("userId", userData.userId);
      eventdData.append("org", userData?.org?.id);
      eventdData.append("eventChatQA", eventChatQA);
      eventdData.append("roomType", roomType);

      if (speakersData && speakersData?.length > 0) {
        eventdData.append("speakers", JSON.stringify(speakersData));
      }
      if (hostedBy && hostedBy?.length > 0) {
        let h = hostedBy
          .filter(item => item.accountId)
          .map(item => {
            return {
              accountId: item?.accountId,
              analytics: item?.analytics,
              allow: item?.allow,
            };
          });
        eventdData.append("hostedBy", JSON.stringify(h));
      }

      if (eventCalendlyLinkAdd && eventCalendlyLink) {
        eventdData.append("eventCalendlyLink", eventCalendlyLink.trim());
      }

      try {
        const data = await EVENT_SERVICES.createEvent(eventdData);
        if (data && data.code === 200) {
          ToastManager.addToast({ title: data.message });
          navigate(
            data?.data?.slug ? `/preview/${data?.data?.slug}` : "/dashboard"
          );
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        ToastManager.addToast({ title: "Error Something went wrong" });
      }
    } else {
      try {
        const data = await EVENT_SERVICES.createEvent(eventdData);
        if (data && data.code === 200) {
          SET_PUBLIC_SESSION("eventDraft", JSON.stringify(data.data));
          ToastManager.addToast({ title: data.message });
          handleLogin();
          setLoading(false);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
          setLoading(false);
        }
      } catch (e) {
        ToastManager.addToast({ title: "Error Something went wrong" });
        setLoading(false);
      }
    }
  };

  const onSelectEventCover = e => {
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      setEventCover("");
      setEventCoverPreview("");
      return;
    }
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setCropEventCover(reader.result?.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    e.target.value = "";
  };

  const eventProcessStep = async () => {
    if (currentStep === "event") {
      if (!checkEventField()) return false;
      if (userData && (!eventTemplateID || !eventMeetingRoomID)) {
        createRoom();
      } else {
        setCurrentStep("confirm");
      }
    } else if (currentStep === "confirm") {
      if (userData) {
        saveEventWork();
      } else {
        setConfirmSaveWork(true);
      }
    }
  };

  const generateEventCover = async () => {
    if (!eventName) {
      ToastManager.addToast({ title: "Please fill event title" });
      return;
    }
    setLoading(true);
    setEventCover("");
    setEventCoverPreview("");
    setEventCoverAi("");
    try {
      const data = await AI_SERVICES.imageGenerate({
        title: eventName,
      });
      if (data.code === 200) {
        setEventCoverPreview(data.data);
        setEventCoverAi(data.data);
      } else {
        ToastManager.addToast({ title: data.message });
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Flex
        className="events6ix-layout-page-middle"
        align="center"
        flex="1"
        direction="column"
      >
        {loading && (
          <FullPageProgress
            background={isDraftEvent ? "#000000" : "transparent"}
            fixed={true}
          />
        )}

        <Flex className="events6ix-layout-page-top no-space-b">
          <h1 className="events6ix-layout-page-top-heading events6ix-layout-page-top-heading-lg events6ix-layout-page-top-heading-line">
            Create Your Event
          </h1>
        </Flex>
        <Flex className="home-page-layout-content">
          <Flex className="home-page-layout-content-col">
            <Box className="home-page-layout-content-box">
              <div className="custom-form">
                <FormControl className="custom-form-group">
                  <label htmlFor="event-name" className="custom-form-label">
                    Event Title
                  </label>
                  <Flex className="custom-form-input-inline">
                    <Input
                      id="event-name"
                      name="eventName"
                      placeholder="Event Title"
                      className="custom-form-input custom-form-input-inline-input"
                      value={eventName}
                      readOnly={currentStep !== "event"}
                      onChange={e => {
                        if (e.target?.value?.length <= 75)
                          setEventName(
                            e.target?.value?.replace(/(<([^>]+)>)/gi, "")
                          );
                      }}
                      maxLength={75}
                    />

                    <div
                      className={`custom-form-input-surfix ${
                        currentStep !== "event" ? "disabled" : ""
                      } `}
                      title="AI Generator"
                      onClick={() => {
                        if (currentStep === "event") {
                          setEventAiType("Title");
                        }
                      }}
                    >
                      <AutoAwesomeIcon style={{ width: 20, height: 20 }} />
                      {eventName ? "Regenerate" : "Generate with 6ix AI"}
                    </div>
                  </Flex>
                </FormControl>

                <FormControl className="custom-form-group">
                  <label htmlFor="event-date" className="custom-form-label">
                    Date/Time
                  </label>
                  <div className="custom-form-date">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      localeText={{
                        clearButtonLabel: "TBD",
                        okButtonLabel: "APPLY",
                      }}
                    >
                      <DesktopDateTimePicker
                        readOnly={currentStep !== "event"}
                        closeOnSelect={false}
                        autoFocus={false}
                        onChange={newValue => {
                          if (currentStep === "event") {
                            newValue?.$d
                              ? setEventDate(newValue.$d)
                              : setEventDate(null);
                          }
                        }}
                        onClose={() => {
                          setEventTBD(true);
                        }}
                        minDateTime={dayjs(new Date())}
                        value={eventDate ? dayjs(eventDate) : eventDate}
                        format="ddd, MMM DD YYYY hh:mm A"
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                          textField: {
                            placeholder: "Date & Time TBD",
                          },

                          mobilePaper: {
                            className: "custom-dateTimePicker",
                          },
                          desktopPaper: {
                            className: "custom-dateTimePicker",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </FormControl>

                {userData ? (
                  <EventHostedAdd
                    hostedBy={hostedBy}
                    setHostedBy={setHostedBy}
                    currentStep={currentStep}
                    createEvent={true}
                    setOrgAccountList={setOrgAccountList}
                  />
                ) : (
                  <FormControl className="custom-form-group">
                    <label
                      htmlFor="event-hosted-add"
                      className="custom-form-label"
                    >
                      Hosted By
                    </label>
                    <Tooltip
                      placement="top"
                      arrow
                      title="Your organization will be auto-selected once you login on the next screen - you will be able to edit hosts after."
                    >
                      <Input
                        id="event-hosted-add"
                        className="custom-form-input"
                        disabled
                        placeholder="Your Organization"
                      />
                    </Tooltip>
                  </FormControl>
                )}

                <FormControl className="custom-form-group">
                  <div className="home-page-layout-content-box-des">
                    <label
                      htmlFor="event-description"
                      className="custom-form-label"
                    >
                      Event Description
                    </label>
                    <Flex className="custom-form-input-inline">
                      <TextareaAutosize
                        id="event-description"
                        name="eventDescription"
                        value={eventDescription}
                        readOnly={currentStep !== "event"}
                        onChange={e => {
                          setEventDescription(
                            e.target?.value?.replace(/(<([^>]+)>)/gi, "")
                          );
                        }}
                        className="custom-form-input custom-form-input-inline-input "
                        placeholder="Enter Description"
                        minRows={2}
                        maxRows={5}
                        // style={{ height: "64px", overflow: "auto" }}
                      />
                      <div
                        className={`custom-form-input-surfix custom-form-input-surfix-top ${
                          currentStep !== "event" ? "disabled" : ""
                        } `}
                        title="AI Generator"
                        onClick={() => {
                          if (currentStep === "event") {
                            setEventAiType("Description");
                          }
                        }}
                      >
                        <AutoAwesomeIcon style={{ width: 20, height: 20 }} />
                        {eventDescription
                          ? "Regenerate"
                          : "Generate with 6ix AI"}
                      </div>
                    </Flex>
                  </div>
                </FormControl>

                {userData ? (
                  <EventSpeakerAdd
                    speakersData={speakersData}
                    setSpeakersData={setSpeakersData}
                    currentStep={currentStep}
                    createEvent={true}
                  />
                ) : (
                  <FormControl className="custom-form-group">
                    <label
                      htmlFor="event-speaker-add"
                      className="custom-form-label"
                    >
                      Speakers
                    </label>
                    <Tooltip
                      placement="top"
                      arrow
                      title="You will be automatically added as a speaker  - once you login, you will be able to edit speakers."
                    >
                      <Input
                        id="event-speaker-add"
                        className="custom-form-input"
                        disabled
                        placeholder="Enter an email(s) and press Enter"
                      />
                    </Tooltip>
                  </FormControl>
                )}

                <Flex
                  className="custom-form-group-row"
                  align="center"
                  css={{ gap: "10px", marginTop: "5px" }}
                >
                  <div className="home-page-layout-content-preview-upload-file">
                    <label htmlFor="event-cover" translate="no">
                      {eventCoverPreview ? (
                        <>
                          <div className="home-page-layout-content-preview-upload-file-preview">
                            <img
                              src={eventCoverPreview}
                              alt="eventCoverPreview"
                            />
                          </div>
                          <Edit />
                        </>
                      ) : (
                        <>
                          <UploadFIle />
                          <span translate="yes">Upload cover</span>
                        </>
                      )}
                      {currentStep === "event" ? (
                        <input
                          id="event-cover"
                          type="file"
                          onChange={onSelectEventCover}
                          name="eventCover"
                          className="home-page-layout-content-preview-upload-file-input"
                          accept="image/x-png,image/gif,image/jpeg,image/jpg,image/webp"
                        />
                      ) : null}
                    </label>
                    {currentStep === "event" && (
                      <div
                        className="home-page-layout-content-preview-upload-file-surfix"
                        title="AI Generator"
                        onClick={generateEventCover}
                      >
                        <AutoAwesomeIcon style={{ width: 20, height: 20 }} />
                        {eventCoverPreview
                          ? "Regenerate"
                          : "Generate with 6ix AI"}
                      </div>
                    )}
                  </div>
                </Flex>
              </div>
            </Box>
          </Flex>
          <Flex className="home-page-layout-content-col" direction="column">
            <EventPreviewBox
              eventCoverPreview={eventCoverPreview}
              eventName={eventName}
              eventDate={eventDate}
              eventTBD={eventTBD}
              hostedBy={hostedBy}
            />
            <EventOptions
              userData={userData}
              roomType={roomType}
              setRoomType={setRoomType}
              eventType={eventType}
              setEventType={setEventType}
              disableSurvey={disableSurvey}
              setDisableSurvey={setDisableSurvey}
              eventChatQA={eventChatQA}
              setEventChatQA={setEventChatQA}
              currentStep={currentStep}
              setEventCalendlyLink={setEventCalendlyLink}
              eventCalendlyLink={eventCalendlyLink}
              eventCalendlyLinkAdd={eventCalendlyLinkAdd}
              setEventCalendlyLinkAdd={setEventCalendlyLinkAdd}
              orgAccountList={orgAccountList}
            />
          </Flex>
        </Flex>
        <Flex
          align="center"
          direction="column"
          justify="center"
          className="home-page-layout-footer"
        >
          <div className="home-page-layout-footer-inner">
            {currentStep !== "event" ? (
              <Button
                color="secondary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-light events6ix-btn-md"
                type="button"
                onClick={() => {
                  setCurrentStep("event");
                }}
                startIcon={<ArrowBack />}
              >
                Back
              </Button>
            ) : null}

            <Button
              color="primary"
              variant="outlined"
              className="events6ix-btn events6ix-btn-primary events6ix-btn-md"
              type="button"
              onClick={() => {
                eventProcessStep();
              }}
              endIcon={<ArrowRight />}
            >
              {currentStep === "event" ? " Next " : "Publish "}
            </Button>
          </div>
          <div className="home-page-layout-footer-dots">
            <CircleFill active={currentStep === "event"} />
            <CircleFill active={currentStep === "confirm"} />
          </div>
        </Flex>
      </Flex>

      {confirmSaveWork ? (
        <div className="event-save-modal">
          <div className="event-save-modal-body">
            <div className="event-save-modal-body-heading">Save your work</div>
            <Button
              color="primary"
              variant="outlined"
              className="events6ix-btn events6ix-btn-primary"
              type="button"
              onClick={() => saveEventWork()}
              endIcon={<ArrowRight />}
            >
              Register / Login
            </Button>
          </div>
        </div>
      ) : null}

      {loginRequest && !userData ? (
        <LoginPopup handleLogin={handleLogin} />
      ) : null}
      {eventAiType ? (
        <EventAiPopup
          open={eventAiType ? true : false}
          type={eventAiType}
          title={eventName}
          searchby={eventAiType === "Title" ? eventName : eventDescription}
          onClose={() => {
            setEventAiType("");
          }}
          setContent={
            eventAiType === "Title" ? setEventName : setEventDescription
          }
        />
      ) : null}

      {cropEventCover && (
        <ImageCropper
          size="xl"
          open={true}
          data={{
            image: cropEventCover,
            title: "Event cover",
            minWidth: 632,
            minHeight: 392,
            ratio: 632 / 392,
            feature: ["square", "freedom"],
          }}
          onCancel={() => {
            setCropEventCover("");
            onSelectEventCover();
          }}
          onUpdate={image => {
            setEventCover(image);
            setEventCoverPreview(URL.createObjectURL(image));
            setCropEventCover("");
          }}
        />
      )}
    </React.Fragment>
  );
};

export default EventCreate;
